<template>
  <div>
    <NavigationBar
      titel="Konfiguration"
      :tabs="tabs"
      :activeTab="activeTab"
    ></NavigationBar>
    <router-view />
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
export default {
  name: "duty-management-config",
  components: { NavigationBar },

  data() {
    return {
      activeTab: `config/duty-types`,
      tabs: [
        // { id: 1, name: "Allgemein", route: `general` },
        { id: 2, name: "Dienstarten", route: `duty-types` },
        // { id: 3, name: "Vorlagen", route: `templates` },
      ],
    };
  },
};
</script>
